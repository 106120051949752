import React, { useState } from 'react';
import { Grid, Card, CardContent, CardActions, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const RequestsList = ({ requests, onEdit, onDelete }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [screenshotUrl, setScreenshotUrl] = useState('');

  const handleViewScreenshot = async (requestId) => {
    try {
      const response = await fetch(`/tickets/${requestId}/screenshot`); // Use relative URL
      if (!response.ok) {
        throw new Error('Screenshot not found');
      }
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setScreenshotUrl(url);
      setOpenDialog(true);
    } catch (error) {
      console.error('Error fetching screenshot:', error);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setScreenshotUrl('');
  };

  return (
    <Grid container spacing={2} style={{ marginTop: '30px' }}>
      {requests.map((request) => {
        let color;
        switch (request.ticketStatus) {
          case 'Open':
            color = 'red';
            break;
          case 'In Progress':
            color = 'orange';
            break;
          case 'Closed':
            color = 'green';
            break;
          default:
            color = 'black';
        }

        return (
          <Grid item xs={12} sm={6} md={4} key={request.id}>
            <Card>
              <CardContent>
                <Typography variant="h6">{request.ticketTitle}</Typography>
                <Typography>Assigned To: {request.assignedTo}</Typography>
                <Typography>Priority: {request.priority}</Typography>
                <Typography>Department: {request.department}</Typography>
                <Typography style={{ color: color }}>Status: {request.ticketStatus}</Typography>
                <Typography>Description: {request.description}</Typography>
              </CardContent>
              <CardActions>
                <Button variant="contained" color="success" onClick={() => onEdit(request)}>
                  Edit
                </Button>
                <Button variant="outlined" color="error" onClick={() => onDelete(request.id)}>
                  Delete
                </Button>
                <Button variant="outlined" color="primary" onClick={() => handleViewScreenshot(request.id)}>
                  View Screenshot
                </Button>
              </CardActions>
            </Card>
          </Grid>
        );
      })}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Screenshot</DialogTitle>
        <DialogContent>
          {screenshotUrl ? <img src={screenshotUrl} alt="Screenshot" style={{ width: '100%' }} /> : 'No screenshot available'}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
          {screenshotUrl && (
            <Button href={screenshotUrl} download="screenshot.png" color="primary">
              Download
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default RequestsList;