import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Container,
  Paper,
  TextField,
  useMediaQuery,
  Select,
  FormControl,
  InputLabel,
  CssBaseline,
  Snackbar,
  Alert
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import RequestsList from './RequestsList';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import logo from './logo.svg';

const apiEndpoint = '/tickets';

const theme = createTheme({
  palette: {
    mode: 'light',
  },
});

const App = () => {
  
  const [sortCriteria, setSortCriteria] = useState({ field: 'id', order: 'desc' }); // Default sort by id desc
  const [tickets, setTickets] = useState([]);
  const [sortOption, setSortOption] = useState('date');
  const [formData, setFormData] = useState({
    id: null,
    ticketTitle: '',
    assignedTo: '',
    priority: 'Low', // Default priority
    department: '',
    ticketStatus: 'Open', // Default status
    description: '',
    screenshot: null,
    screenshotType: ''
  });
  const [showForm, setShowForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [itEmails, setItEmails] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const predefinedStatuses = ['Open', 'In Progress', 'Closed'];
  const predefinedPriorities = ['Low', 'Medium', 'High'];

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await axios.get(apiEndpoint, {
          params: {
            sortField: sortCriteria.field, // Ensure this matches the backend column name
            sortOrder: sortCriteria.order,
          },
        });
        setTickets(response.data.data);
      } catch (error) {
        console.error('Error fetching tickets:', error);
      }
    };
  
    fetchTickets();
  }, [sortCriteria]);

  const handleToggleForm = () => {
    setShowForm(!showForm);
    if (isEditing) {
      setIsEditing(false);
      setFormData({
        id: null,
        ticketTitle: '',
        assignedTo: '',
        priority: 'Low',
        department: '',
        ticketStatus: 'Open',
        description: '',
        screenshot: null,
        screenshotType: ''
      });
    }
  };

  const handleEdit = (ticket) => {
    setIsEditing(true);
    setFormData(ticket);
    setShowForm(true);
  };

  const handleDelete = async (id) => {
    const pin = prompt('Enter PIN to delete:');
    if (!pin) return;

    try {
      const pinResponse = await axios.post('/verify-pin', { pin });
      if (!pinResponse.data.valid) {
        alert('Invalid PIN');
        return;
      }

      await axios.delete(`${apiEndpoint}/${id}`);

      const response = await axios.get(apiEndpoint);
      setTickets(response.data.data);
    } catch (error) {
      console.error('Error deleting ticket:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataObj = new FormData();
    formDataObj.append('ticketTitle', formData.ticketTitle);
    formDataObj.append('assignedTo', formData.assignedTo);
    formDataObj.append('priority', formData.priority);
    formDataObj.append('department', formData.department);
    formDataObj.append('ticketStatus', formData.ticketStatus);
    formDataObj.append('description', formData.description);
    if (formData.screenshot) {
      formDataObj.append('screenshot', formData.screenshot);
      formDataObj.append('screenshotType', formData.screenshot.type);
    }

    try {
      if (formData.id) {
        await axios.put(`${apiEndpoint}/${formData.id}`, formDataObj, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      } else {
        await axios.post(apiEndpoint, formDataObj, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      }

      setFormData({
        id: null,
        ticketTitle: '',
        assignedTo: '',
        priority: 'Low', // Reset to default priority
        department: '',
        ticketStatus: 'Open', // Reset to default status
        description: '',
        screenshot: null,
        screenshotType: ''
      });

      setIsEditing(false);

      const response = await axios.get(apiEndpoint);
      setTickets(response.data.data);

      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      screenshot: file,
      screenshotType: file ? file.type : ''
    }));
  };

  const handleSortChange = (field) => {
    setSortCriteria((prev) => ({
      field,
      order: prev.field === field && prev.order === 'asc' ? 'desc' : 'asc',
    }));
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => console.log('Open mobile menu')}>
            <MenuIcon />
          </IconButton>
          <img src={logo} alt="Logo" style={{ height: 40, marginRight: 10 }} />
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Ticket System
          </Typography>
          <Button color="inherit" onClick={handleToggleForm}>
            {showForm ? 'Close Form' : 'Add Ticket'}
          </Button>
        </Toolbar>
      </AppBar>
      <Container style={{ paddingTop: 80 }}>
        <div>
          <Button onClick={() => handleSortChange('id')}>Sort by ID</Button>
          <Button onClick={() => handleSortChange('assignedTo')}>Sort by Assigned To</Button>
          <Button onClick={() => handleSortChange('ticketStatus')}>Sort by Status</Button>
        </div>
        {showForm && (
          <Paper style={{ padding: 16 }}>
            <form onSubmit={handleSubmit}>

              <TextField
                label="Ticket Title"
                name="ticketTitle"
                value={formData.ticketTitle}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Assigned To</InputLabel>
                <Select
                  native
                  name="assignedTo"
                  value={formData.assignedTo}
                  onChange={handleChange}
                >
                  <option aria-label="None" value="" />
                  <option value="Baylor">Baylor</option>
                  <option value="Rob">Rob</option>
                  <option value="Dan">Dan</option>
                  <option value="Peter">Peter</option>
                  <option value="Ken">Ken</option>
                  <option value="Jiri">Jiri</option>
                  <option value="Mike">Mike</option>
                  <option value="Joe">Joe</option>
                  <option value="Mitchell">Mitchell</option>
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Priority</InputLabel>
                <Select
                  native
                  name="priority"
                  value={formData.priority}
                  onChange={handleChange}
                >
                  {['Low', 'Medium', 'High'].map((priority) => (
                    <option key={priority} value={priority}>
                      {priority}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Department"
                name="department"
                value={formData.department}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Status</InputLabel>
                <Select
                  native
                  name="ticketStatus"
                  value={formData.ticketStatus}
                  onChange={handleChange}
                >
                  {['Open', 'In Progress', 'Closed'].map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                fullWidth
                margin="normal"
                multiline
              />
              <input 
                type="file" 
                accept="image/*" 
                onChange={handleFileChange}
              />
              <Button type="submit" color="primary" variant="contained">
                {isEditing ? 'Update Ticket' : 'Add Ticket'}
              </Button>
              </form>
          </Paper>
        )}
        <RequestsList requests={tickets} onEdit={handleEdit} onDelete={handleDelete} />
      </Container>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success">
          {isEditing ? 'Ticket updated successfully!' : 'Ticket added successfully!'}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default App;